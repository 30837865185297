import React, { useState } from 'react';
import { Modal, Form, Input, Button, InputNumber } from 'antd';
import { postRequest } from '../../api/http';
import { JobsServiceApi } from '../../api';
import toastr from 'toastr';
import uuid from 'react-uuid';
import { LoadingView } from '../common';
const AddQuestionModalAi = ({ visible, onClose, onSubmit, initialValues ,questionData,setloading}) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values=form.getFieldsValue()
    let requiredSkillsArray = Array.isArray(values?.required_skills)
    ? values?.required_skills
    : values?.required_skills?.split(',').map(skill => skill.trim());
     const data={
      job_title:values?.job_title,
      required_skills: requiredSkillsArray,
      roles:values?.roles,
      experience:values?.experience,
      previously_generated_questions:questionData?.map((q)=>{return q.question}),
      question_count:values?.question_count,
      user_prompt:values?.user_prompt
     }
     setloading(true)
    try {
      const response = await postRequest(JobsServiceApi.getQuestionsAi(),data);
        const generatedQuestions=transformMCQData(response.generated_mcq)
          toastr.success("Question Added Successfully")
          console.log(response,"questions")
          onSubmit(generatedQuestions)
          onClose()
          
  } catch (error) {
      toastr.error(error?.message);
      console.log(error?.message)
  }finally {
    setloading(false); // Make sure to set loading to false in the finally block
  }
  };
  const transformMCQData = (mcqData) => {
    return mcqData?.map((item) => {
      const options = item.options; // Get the options array
      return {
        question: `<p>${item.question}</p>`,
        optionA: options[0],
        optionB: options[1],
        optionC: options[2],
        optionD: options[3],
        correctAnswer: `OPTION_${String.fromCharCode(65 + item.correct_answer.index)}`, // Convert index to "OPTION_A", "OPTION_B", etc.
        id: uuid() // Generate a unique ID
      };
    });
  };

  return (
    <>
      <Modal
        title="Add AI Question"
        visible={visible}
        onCancel={onClose}
        onOk={handleSubmit}
        okText="Submit"
      >
        <Form
          form={form}
          layout="vertical"
          name="add_question_form"
          initialValues={{
            job_title: initialValues?.title ? initialValues?.title : "",
            experience: initialValues?.experienceLevel ? initialValues?.experienceLevel : "",
            required_skills: initialValues?.skills
              ? initialValues?.skills
              : null,
          }}
        >
          <Form.Item
            name="job_title"
            label="Job Title"
            rules={[{ required: true, message: 'Please input the job title!' }]}
          >
            <Input placeholder="Enter job title" />
          </Form.Item>

          <Form.Item
            name="required_skills"
            label="Required Skills"
            // rules={[{ required: true, message: 'Please input required skills!' }]}
          >
            <Input placeholder="Enter required skills" />
          </Form.Item>

          <Form.Item
            name="experience"
            label="Experience (Fresher or experienced)"
            // rules={[{ required: true, message: 'Please input the experience!' }]}
          >
            <Input placeholder="Enter experience level (e.g., 2 years in software development)" />
          </Form.Item>

          <Form.Item
            name="roles"
            label="Roles and responsibilities"
            // rules={[{ required: true, message: 'Please input the roles!' }]}
          >
            <Input placeholder="Describe the roles and responsibilities (e.g., Develop and maintain web applications)"/>
          </Form.Item>
          <Form.Item
            label="Enter the number of questions to be generated(maximum:6)"
            name="question_count"
          >
            <InputNumber min={1} max={6} />
          </Form.Item>
          <Form.Item
            name="user_prompt"
            label="Enter a Prompt for AI-Generated Questions"
            // rules={[{ required: true, message: 'Please input the user prompt!' }]}
          >
            <Input.TextArea placeholder="Enter a prompt (e.g., Create questions on data structures and algorithms for software developers)" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddQuestionModalAi;
