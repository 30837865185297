import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import moment from "moment";
import { postRequest } from "../../api/http";
import jobsServiceApi from "../../api/jobsServiceApi";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
const { TextArea } = Input;
const { Option } = Select;

const AiModal = ({ visible, onClose, onSubmit, initialValues }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [form] = Form.useForm();
  console.log(initialValues, "form values");
  // const handleRegistration = async (eventId) => {
  //   const data = {
  //     eventId: eventId,
  //   };

  //   try {
  //     console.log("data", data);
  //     await postRequest(employersServiceApi.registerEvent(), data);
  //     toastr.success("You have been successfully registered for the event");

  //     // Handle success response
  //     // console.log("Registration success:", response);
  //   } catch (e) {
  //     Modal.error({
  //       title: "Error",
  //       content: e.response?.data?.message || "Something went wrong...",
  //     });
  //   }
  // };
  const handlePlaceSelected = (place) => {
    console.log("place in search", place);
    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setSelectedPlace({ ...place, location });

    setSearchText(place.formatted_address);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      handlePlaceSelected(place);
    } else {
      alert("Please enter text");
    }
  };
  const onFinish = async (values) => {
    // Simulate API call to fetch AI-generated content
    console.log("Form values: ", values);
    const locationValue = selectedPlace ? selectedPlace.name : values.location;
    const updatedValues = {
      ...values,
      location: locationValue, 
    };
    try {
      const apiResponse = await postRequest(
        jobsServiceApi.getDescription(),
        updatedValues
      );
      console.log(apiResponse, "apiresponse");
      const combinedDescription = `<p>${
        apiResponse?.generated_job_description?.job_description
      }</p><h4>Roles and Responsibilities:</h4><ul>${apiResponse?.generated_job_description?.roles_and_responsibilities
        .map((role) => `<li>${role}</li>`)
        .join(
          ""
        )}</ul><h4>Required Skills:</h4><ul>${apiResponse?.generated_job_description?.required_skills
        .map((skill) => `<li>${skill}</li>`)
        .join("")}</ul>`;

      // Call the onSubmit function from parent and pass combined description
      onSubmit(combinedDescription.toString());
      onClose(); // Close modal after submitting
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went ...",
      });
    }
  };
  const disablePastDates = (current) => {
    return current && current.isBefore(moment(), "day");
  };
  const disableJobStartDate = (current) => {
    const applicationEndDate = form.getFieldValue("application_end_date");
    if (applicationEndDate) {
      return (
        current &&
        (current.isSameOrBefore(applicationEndDate, "day") ||
          current.isBefore(moment(), "day"))
      );
    }
    return current && current.isBefore(moment(), "day");
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        job_title: initialValues?.title ? initialValues?.title : "",
        language: initialValues?.languages
        ? Array.isArray(initialValues.languages)
          ? initialValues.languages.join(",") // Convert array to comma-separated string
          : initialValues.languages
        : "",
        open_position: initialValues?.openPositions
          ? initialValues?.openPositions
          : null,
        application_end_date: initialValues?.deadLine
          ? moment(initialValues.deadLine)
          : null,
        job_start_date: initialValues?.startDate
          ? moment(initialValues.startDate)
          : null,
        location: initialValues?.location ? initialValues?.location : null,
        required_skills: initialValues?.skills
        ? Array.isArray(initialValues.skills)
          ? initialValues.skills.join(",") // Convert array to comma-separated string
          : initialValues.skills
        : "",
        working_hours: initialValues?.workingHours
          ? initialValues?.workingHours
          : null,
        compensation: initialValues?.compensation
          ? initialValues?.compensation
          : null,
      });
    }
  }, [initialValues, form]);
  return (
    <div>
      <Modal
        title="Craft Your Job Description"
        visible={visible}
        onCancel={onClose}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            job_title: initialValues?.title ? initialValues?.title : "",
            language: initialValues?.languages ? initialValues?.languages : "",
            open_position: initialValues?.openPositions
              ? initialValues?.openPositions
              : null,
            application_end_date: initialValues?.deadLine
              ? moment(initialValues.deadLine)
              : null,
            job_start_date: initialValues?.startDate
              ? moment(initialValues.startDate)
              : null,
            location: initialValues?.location ? initialValues?.location : null,
            required_skills: initialValues?.skills
              ? initialValues?.skills
              : null,
            working_hours: initialValues?.workingHours
              ? initialValues?.workingHours
              : null,
            compensation: initialValues?.compensation
              ? initialValues?.compensation
              : null,
          }}
        >
          <Form.Item
            label="Job Title"
            name="job_title"
            rules={[{ required: true, message: "Please enter the job title" }]}
          >
            <Input placeholder="Enter your job title E.g., Software Engineer, Product Manager" />
          </Form.Item>

          <Form.Item
            name="experience"
            label="Experience (Fresher or experienced)"
            // rules={[{ required: true, message: 'Please input the experience!' }]}
          >
            <Input placeholder="Enter experience level (e.g., 2 years in software development)" />
          </Form.Item>

          <Form.Item
            label="Language"
            name="language"
          >
            <Input placeholder="Enter languages separated by commas (e.g., English, Bengali)" />
          </Form.Item>

          <Form.Item
            label="Work Mode"
            name="work_mode"
          >
            <Input placeholder="E.g., On-site, Remote" />
          </Form.Item>

          <Form.Item
            label="Open Positions"
            name="open_position"
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Application End Date"
            name="application_end_date"
          >
            <DatePicker format="YYYY-MM-DD" disabledDate={disablePastDates} />
          </Form.Item>

          <Form.Item
            label="Job Start Date"
            name="job_start_date"
          >
            <DatePicker format="YYYY-MM-DD" disabledDate={disableJobStartDate} />
          </Form.Item>

          <Form.Item
            label="Location"
            name="location"
          >
              <Autocomplete
                onLoad={(autocomplete) => {
                  setSearchResult(autocomplete);
                }}
                onPlaceChanged={onPlaceChanged}
                restrictions={{ country: "IN" }} // Set restrictions to India
                // bounds={{ east: 97.3, west: 68.7, north: 37.6, south: 8.4 }}
              >
                <input
                  type="text"
                  className="location-input"
                  placeholder="Search for a place"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Autocomplete>
          </Form.Item>

          <Form.Item
            label="Education"
            name="education"
          >
            <Input placeholder="E.g., Bachelor’s Degree in Computer Science" />
          </Form.Item>

          <Form.Item
            label="Required Skills"
            name="required_skills"
          >
            <TextArea
              rows={2}
              placeholder="Enter skills separated by commas (e.g., Tally, Financial Reporting)"
            />
          </Form.Item>

          <Form.Item
            label="Roles"
            name="roles and reponsibilities"
          >
            <TextArea
              rows={1}
              placeholder="Describe the roles and responsibilities"
            />
          </Form.Item>

          <Form.Item
            label="Working Hours per day"
            name="working_hours"
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Compensation (in INR)"
            name="compensation"
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item>
            <Button type="primary"onClick={form.submit} >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AiModal;
